import React, { useState, MouseEvent, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { data } from '../data';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DetailsContact from '../components/DetailsContact';
import NotFound from './NotFound';
import MapLocation from '../components/MapLocation';

const CustomPrevArrow = (props: { onClick: () => void }) => {
  const { onClick } = props;
  return (
    <Box
      sx={{
        width: { md: 50, sm: 40, xs: 30 },
        height: { md: 50, sm: 40, xs: 30 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        borderRadius: '50%',
        position: 'absolute',
        top: { sm: '50%', xs: '55%' },
        left: { md: -70, sm: 30, xs: 10 },
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
        opacity: '0.75',
      }}
      onClick={onClick}
    >
      <ArrowBackIosIcon
        sx={{
          fontSize: { sm: 18, xs: 14 },
          color: '#000',
          marginLeft: 0.75,
        }}
      />
    </Box>
  );
};

const CustomNextArrow = (props: { onClick: () => void }) => {
  const { onClick } = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        width: { md: 50, sm: 40, xs: 30 },
        opacity: '0.75',
        height: { md: 50, sm: 40, xs: 30 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        position: 'absolute',
        top: { sm: '50%', xs: '55%' },
        right: { md: -70, sm: 30, xs: 15 },
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
        borderRadius: '50%',
      }}
    >
      <ArrowForwardIosIcon
        sx={{ fontSize: { sm: 18, xs: 14 }, color: '#000' }}
      />
    </Box>
  );
};

const DetailsCard = () => {
  const [toggler, setToggler] = useState<boolean>(false);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);

  const mainImageSliderRef = useRef<Slider | null>(null);

  const { id } = useParams<{ id: string }>();

  const selectedApartment = data.find(
    (apartment) => apartment.id === Number(id)
  );

  if (!selectedApartment) {
    return <NotFound />;
  }

  const changeImageIndex = (step: number) => {
    const newImageIndex = activeImageIndex + step;
    if (newImageIndex >= 0 && newImageIndex < selectedApartment.images.length) {
      setActiveImageIndex(newImageIndex);
    }
  };

  const openLightbox = (imageIndex: number) => {
    setActiveImageIndex(imageIndex);
    setToggler(!toggler);
  };

  const mainImageSliderSettings = {
    arrows: true,
    prevArrow: <CustomPrevArrow onClick={() => changeImageIndex(-1)} />,
    nextArrow: <CustomNextArrow onClick={() => changeImageIndex(1)} />,
  };

  const thumbnailSliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleThumbnailClick = (index: number) => {
    setActiveImageIndex(index);
    if (mainImageSliderRef.current) {
      mainImageSliderRef.current.slickGoTo(index);
    }
  };

  const handleExternalLinkClick = () => {
    window.open(selectedApartment.url, '_blank');
  };

  return (
    <Box
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{
          flex: '0 1',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 700, mt: 15 }}>
          {selectedApartment.name}
        </Typography>
        <Box
          sx={{
            width: '100%',
            maxWidth: '700px',
            margin: '0 auto',
            mb: 5,
          }}
        >
          {/* Main Image Slider */}
          <Slider
            {...mainImageSliderSettings}
            ref={(slider) => (mainImageSliderRef.current = slider)}
          >
            {selectedApartment.images.map((image, index) => (
              <Box key={index} sx={{ mt: 3, borderRadius: '30px' }}>
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '65.25%',
                    position: 'relative',
                  }}
                >
                  <img
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      openLightbox(index);
                    }}
                    key={index}
                    src={image}
                    alt={`img ${index}`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '30px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </Box>
            ))}
          </Slider>
          <FsLightbox
            toggler={toggler}
            sources={selectedApartment.images}
            sourceIndex={activeImageIndex}
          />
        </Box>
      </Box>
      {/* Thumbnail Slider */}
      <Box
        sx={{
          display: { md: 'block', xs: 'none' },
          width: '100%',
          maxWidth: '500px',
          height: 100,
          margin: '0 auto',
          mb: 5,
        }}
      >
        <Slider {...thumbnailSliderSettings}>
          {selectedApartment.images.map((image, index) => (
            <Box
              key={index}
              sx={{
                margin: '0 auto',
                cursor: 'pointer',
                '& img': {
                  height: 110,
                },
              }}
            >
              <img
                style={{ width: '100%' }}
                src={image}
                alt={`img ${index}`}
                onClick={() => handleThumbnailClick(index)}
              />
            </Box>
          ))}
        </Slider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{
            mb: 5,
            border: 'none',
            bgcolor: '#003580',
            borderRadius: 2,
            width: { sm: 200, xs: 175 },
            height: { sm: 40, xs: 30 },
            ':hover': {
              backgroundColor: '#fff',
              border: '2px solid var(--primary, #003580)',
              color: '#003580',
            },
          }}
          onClick={handleExternalLinkClick}
        >
          Open on Booking
        </Button>
      </Box>
      <MapLocation
        longitude={selectedApartment.longitude}
        latitude={selectedApartment.latitude}
      />
      <DetailsContact />
    </Box>
  );
};

export default DetailsCard;
