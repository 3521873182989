import React, {
  useState,
  useEffect,
  useRef,
  FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { format } from 'date-fns';
import { data } from '../data';

const useStyles = makeStyles()((theme) => ({
  btn: {
    fontSize: 14,
    color: '#222222',
    background: '#fff',
    border: 'none',
    height: 70,
    ':hover': {
      backgroundColor: '#DCDCDC',
      border: 'none',
      color: '#000',
      height: 70,
    },
  },
}));

const ApartmentsSearch: FC<{
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  availability: boolean[];
  setAvailability: Dispatch<SetStateAction<boolean[]>>;
}> = ({ availability, setAvailability, loading, setLoading }) => {
  const { classes } = useStyles();

  const calendarContainerRef = useRef<HTMLDivElement | null>(null);
  const guestsContainerRef = useRef<HTMLDivElement | null>(null);

  const [guestsCount, setGuestsCount] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showAddGuests, setShowAddGuests] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [showSnackbar, setShowSnackbar] = useState(false);

  // useEffect для обработки клика вне области календаря и контейнера гостей
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarContainerRef.current &&
        !calendarContainerRef.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }

      if (
        guestsContainerRef.current &&
        !guestsContainerRef.current.contains(event.target as Node)
      ) {
        setShowAddGuests(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Формирование объекта для выбора диапазона дат
  const selectionRange = {
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
    key: 'selection',
  };

  // Функции для форматирования выбранных дат
  const checkInDate = startDate
    ? startDate.toString().split(' ')[1] +
      ' ' +
      startDate.toString().split(' ')[2]
    : '';

  const checkOutDate = endDate
    ? endDate.toString().split(' ')[1] + ' ' + endDate.toString().split(' ')[2]
    : '';

  // Функция для обработки клика на кнопке показа/скрытия календаря
  const handleToggleDatePicker = () => {
    setShowAddGuests(false);
    setShowDatePicker(!showDatePicker);
  };

  // Функция для обработки клика на кнопке показа/скрытия контейнера гостей
  const handleToggleAddGuests = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.dataset.issearch !== 'true') {
      setShowDatePicker(false);
      setShowAddGuests(!showAddGuests);
    }
  };

  // Обработчик выбора дат в календаре
  const handleSelect = (ranges: any) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  // Функция для проверки доступности апартаментов
  const checkApartmentAvailability = async (
    url: string,
    checkin: string,
    checkout: string
  ) => {
    try {
      const response = await axios.get(
        `https://benidormholidayapts.com/check_availability?url=${url}&checkin=${checkin}&checkout=${checkout}`
      );      
      return response.data.availability;
    } catch (error) {
      console.error('Ошибка:', error);
      return false;
    }
  };

  // Обработчик клика по кнопке поиска апартаментов
  const handleSearchClick = async () => {
    if (!startDate || !endDate || guestsCount === 0) {
      setShowSnackbar(true);
    } else {
      try {
        const checkin = format(startDate, 'yyyy-MM-dd');
        const checkout = format(endDate, 'yyyy-MM-dd');
        setLoading(true);
        const availabilityPromises = data.map(async (apartment) => {
          const isAvailable = await checkApartmentAvailability(
            apartment.url,
            checkin,
            checkout
          );
          return isAvailable;
        });
        const apartmentAvailabilities = await Promise.all(availabilityPromises);
        setAvailability(apartmentAvailabilities);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        sx={{ zIndex: 2, position: 'absolute', mt: { xs: 25, sm: 35, lg: 60 } }}
      >
        <Box
          sx={{
            borderRadius: '50px',
            display: 'flex',
            background: '#FFF',
            boxShadow: '0px 4px 55px 0px rgba(0, 0, 0, 0.07)',
            width: { md: 550, sm: 375, xs: 300 },
            height: { md: 90, sm: 80 },
            flexShrink: 0,
            mb: 1,
            padding: '0px 10px',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              fontSize: 18,
              alignItems: 'center',
              color: 'var(--black-shade, #677071)',
            }}
          >
            <Button
              className={classes.btn}
              onClick={handleToggleDatePicker}
              sx={{
                width: { md: 300, sm: 200, xs: 160 },
                height: 80,
              }}
            >
              <Box sx={{ mr: { xs: 2, sm: 5 } }}>
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                  Check in
                </Typography>
                {checkInDate ? (
                  <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
                    {checkInDate}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: 12 }}>Add dates</Typography>
                )}
              </Box>
              <Box>
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                  Check out
                </Typography>
                {checkOutDate ? (
                  <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
                    {checkOutDate}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
                    Add dates
                  </Typography>
                )}
              </Box>
            </Button>
            <Button
              onClick={handleToggleAddGuests}
              className={classes.btn}
              sx={{
                display: 'flex',
                width: { md: 100, sm: 90, xs: 90 },
                mr: { md: 2, sm: 1 },
                height: 80,
              }}
            >
              <Box sx={{ mr: 'auto', pl: 1, width: 62 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                  Guests
                </Typography>
                {guestsCount > 0 ? (
                  <>
                    <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
                      {guestsCount}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
                      Add guests
                    </Typography>
                  </>
                )}
              </Box>
            </Button>
            <Button
              onClick={handleSearchClick}
              sx={{
                height: 50,
                width: { md: 120, sm: 80, xs: 50 },
              }}
            >
              <Typography
                sx={{ fontSize: 16, display: { sm: 'block', xs: 'none' } }}
              >
                Search
              </Typography>
              <SearchIcon sx={{ display: { sm: 'none', xs: 'block' } }} />
            </Button>
          </Box>
        </Box>
        {showDatePicker && (
          <Box ref={calendarContainerRef} sx={{ position: 'absolute' }}>
            <DateRangePicker
              ranges={[selectionRange]}
              minDate={new Date()}
              rangeColors={['#13A6BA']}
              onChange={handleSelect}
            />
          </Box>
        )}
        {showAddGuests && (
          <Box
            ref={guestsContainerRef}
            sx={{ position: 'absolute', ml: '270px' }}
          >
            <Box
              sx={{
                bgcolor: '#fff',
                width: { md: 220, sm: 180, xs: 150 },
                ml: { md: 'auto', sm: -17, xs: -15 },
                height: { md: 80, sm: 70, xs: 70 },
                borderRadius: '50px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: { sm: '0px 40px 0px 40px', xs: '0px 10px 0px 10px' },
                boxShadow: ' 0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Typography sx={{ color: '#222222' }}>Guests</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => setGuestsCount(guestsCount - 1)}
                  sx={{ height: 30, width: 30 }}
                  disabled={guestsCount < 1}
                >
                  -
                </Button>
                <Typography sx={{ p: 1 }}>{guestsCount}</Typography>
                <Button
                  onClick={() => setGuestsCount(guestsCount + 1)}
                  sx={{ height: 30, width: 30 }}
                  disabled={guestsCount > 4}
                >
                  +
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="error">
          {(!startDate || !endDate) && guestsCount === 0
            ? 'Add dates and number of guests'
            : !startDate || !endDate
            ? 'Add dates'
            : 'Add number of guests'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApartmentsSearch;
