import React, { FC, useState } from 'react';
import { Box, Icon, Snackbar, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const DetailsContact: FC = () => {
  const [isCopied, setIsCopied] = useState(false);
  const phoneNumber = '+34 658 84 05 51';

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          setIsCopied(true);
        })
        .catch((err) => {
          console.error('Ошибка при копировании в буфер обмена:', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = phoneNumber;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setIsCopied(true);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        p: 2,
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: { md: 'flex', xs: 'block' },
          mt: { md: 'none', xs: 5 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mr: 2,
            alignItems: 'end',
            cursor: 'pointer',
          }}
          onClick={copyToClipboard}
        >
          <Typography
            sx={{
              mr: 2,
              mb: { md: 0, xs: 2.5 },
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Tel. {phoneNumber}
          </Typography>
          <Snackbar
            open={isCopied}
            autoHideDuration={1500}
            onClose={() => setIsCopied(false)}
            message="Copied"
          />
        </Box>
        <a
          href="https://www.facebook.com/profile.php?id=100070487254920"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon sx={{ fontSize: 30, color: '#000', mr: 3 }}>
            <WhatsAppIcon sx={{ fontSize: 30 }} />
          </Icon>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100070487254920"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon sx={{ fontSize: 30, color: '#000' }}>
            <FacebookIcon sx={{ fontSize: 30 }} />
          </Icon>
        </a>
      </Box>
      <Typography sx={{ fontSize: 12 }}>
        © {new Date().getFullYear()} Benidorm{' '}
      </Typography>
    </Box>
  );
};

export default DetailsContact;
