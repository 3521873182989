import React from 'react';
import Box from '@mui/material/Box';
import Header from './components/Header';
import { Container } from '@mui/material';
import Home from './pages/Home';
import DetailsCard from './pages/DetailsCard';
import { Routes, Route } from 'react-router-dom';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Box
      sx={{ background: 'var(--accent-2-shade, #FBEDDA)', minHeight: '100vh' }}
    >
      <Container
        maxWidth={false}
        sx={{
          maxWidth: 1586,
        }}
      >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apartments/:id" element={<DetailsCard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </Box>
  );
}

export default App;
