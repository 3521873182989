import { createTheme } from '@mui/material';
export const theme = createTheme({
  palette: {
    primary: {
      main: '#13A6BA',
    },
  },
  typography: {
    fontFamily: ['Noto Serif', 'serif'].join(','),
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            background: '#13A6BA',
            color: 'white',
            borderRadius: '32px',
            fontFamily: ['Noto Serif', 'serif'].join(','),
            fontWeight: 600,
            textTransform: 'none',
            lineHeight: 0,
            display: 'flex',
            justifyContent: 'center',
            minWidth: 0,
            border: '2px solid var(--primary, #13A6BA)',
            ':hover': {
              backgroundColor: '#fff',
              border: '2px solid var(--primary, #13A6BA)',
              color: '#13A6BA',
            },
          },
        },
      ],
    },
  },
});
