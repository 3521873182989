import React, { FC } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Button, Typography } from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CustomPrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <Box
      sx={{
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
        opacity: '0.75',
      }}
      onClick={onClick}
    >
      <ArrowBackIosIcon
        sx={{
          fontSize: 12,
          color: '#000',
          marginLeft: 0.5,
        }}
      />
    </Box>
  );
};

const CustomNextArrow = (props: any) => {
  const { onClick } = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        width: 30,
        opacity: '0.75',
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
        borderRadius: '50%',
      }}
    >
      <ArrowForwardIosIcon sx={{ fontSize: 12, color: '#000' }} />
    </Box>
  );
};

export { CustomPrevArrow, CustomNextArrow };

// -------------------------------------------------------------------------------//

interface ApartmentsCardProps {
  id: number;
  name: string;
  images: string[];
  availability: boolean;
  url: string;
}

const ApartmentCard: FC<ApartmentsCardProps> = ({
  id,
  name,
  images,
  availability,
  url,
}) => {
  const sliderSettings = {
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  // const navigate = useNavigate();

  const handleSeeDetailsClick = () => {
    window.open(`/apartments/${id}`, '_blank');
    window.scrollTo(0, 0);
  };

  const handleExternalLinkClick = () => {
    window.open(url, '_blank'); // Открываем ссылку в новой вкладке
  };

  return (
    <>
      <Box
        sx={{
          width: { sm: 430, xs: 350 },
          height: { sm: 630, xs: 550 },
          backgroundColor: availability ? 'white' : '#d1d1d1', // Light grey background
          borderRadius: '40px',
          display: 'flex',
          justifyContent: 'center',
          ':hover': {
            boxShadow: '2px 4px 55px 0px rgba(0, 0, 0, 0.11)',
          },
        }}
      >
        <Box sx={{ width: { sm: 380, xs: 300 }, p: 3 }}>
          <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <img
                key={index}
                className="card-image"
                src={image}
                alt={`img ${index}`}
              />
            ))}
          </Slider>
          <Typography
            sx={{
              fontSize: { sm: 30, xs: 24 },
              fontWeight: 700,
              mb: 3.25,
              mt: 4,
            }}
          >
            {name}
          </Typography>
          <Typography sx={{ color: '#677071', fontSize: { sm: 18, xs: 14 } }}>
            BENIDORM, SPAIN
          </Typography>
          {availability ? (
            <>
              <Box sx={{ mt: 3.5 }}>
                <Button
                  sx={{
                    border: 'none',
                    bgcolor: '#003580',
                    borderRadius: 2,
                    float: 'right',
                    width: { sm: 200, xs: 175 },
                    height: { sm: 40, xs: 30 },
                    mb: 1.5,
                    ':hover': {
                      backgroundColor: '#fff',
                      border: '2px solid var(--primary, #003580)',
                      color: '#003580',
                    },
                  }}
                  onClick={handleExternalLinkClick}
                >
                  Open on Booking
                </Button>
                <Button
                  onClick={handleSeeDetailsClick}
                  sx={{
                    width: { sm: 380, xs: 300 },
                    height: { sm: 73, xs: 65 },
                    fontSize: { sm: 22, xs: 18 },
                    backgroundColor: 'primary',
                  }}
                >
                  See details
                </Button>
              </Box>
            </>
          ) : (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: { sm: 18, xs: 14 },
                mb: 5,
                mt: 7,
              }}
            >
              Unavailable for these dates
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ApartmentCard;
