import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ApartmentCard from './ApartmentCard/index';
import { data } from '../data';
import SkeletonCard from './ApartmentCard/SkeletonCard';
import { animateScroll as scroll } from 'react-scroll';

const ApartmentsList: FC<{ availability: boolean[]; loading: boolean }> = ({
  availability,
  loading,
}) => {
  // Ссылка на элемент "SkeletonCard" для прокрутки к нему при загрузке
  const skeletonRef = useRef<HTMLDivElement>(null);

  // Прокрутка к элементу "SkeletonCard" при загрузке, если loading === true
  useEffect(() => {
    if (loading && skeletonRef.current) {
      scroll.scrollTo(skeletonRef.current.offsetTop - 150, {
        duration: 800,
        smooth: 'easeInOutQuart',
        offset: -50,
      });
    }
  }, [loading]);

  const cardsPerPage = 3;
  const totalCards = data.length;

  const [currentPage, setCurrentPage] = useState(0);

  const startIndex = currentPage * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Создание карточек на основе данных
  const cards = data.map((apartment, index) => (
    <ApartmentCard
      key={apartment.id}
      id={apartment.id}
      name={apartment.name}
      images={apartment.images}
      url={apartment.url}
      availability={availability[index]}
    />
  ));

  // Отображаемые карточки на текущей странице
  const displayedCards = cards.slice(startIndex, endIndex);

  // Вычисление общего количества страниц
  const totalPages = Math.ceil(totalCards / cardsPerPage);

  // Обработчики навигации по страницам
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Возвращаемые компоненты в зависимости от загрузки
  if (loading) {
    // Возвращаем карточки-заглушки, если данные загружаются
    return (
      <>
        <div ref={skeletonRef}>
          <Grid
            sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }}
            container
            spacing={3}
          >
            {[...new Array(3)].map((_, index) => (
              <Grid item key={index}>
                <SkeletonCard />
              </Grid>
            ))}
          </Grid>
        </div>
      </>
    );
  }

  // Возвращаем основные карточки при наличии данных
  return (
    <>
      {availability.length > 0 && (
        <Box
          sx={{
            mb: { sm: 10, xs: 5 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }}
            container
            spacing={3}
          >
            {displayedCards.map((card, index) => (
              <Grid item key={index}>
                {card}
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              mt: 5,
              ml: { lg: 'auto', sm: 'none' },
              mr: { lg: 5, sm: 'none' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                width: 50,
                flex: 1,
                height: 50,
                borderRadius: '9px',
                fontSize: 24,
              }}
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              &lt;
            </Button>
            <Box sx={{ padding: '0px 15px 0px 15px', display: 'flex' }}>
              <Typography sx={{ fontSize: 14, mr: 1 }}>
                You are on page
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                {currentPage + 1}
              </Typography>
            </Box>
            <Button
              sx={{
                width: 50,
                flex: 1,
                height: 50,
                borderRadius: '9px',
                fontSize: 24,
              }}
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
            >
              &gt;
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ApartmentsList;
