import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonCard = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={640}
    viewBox="0 0 400 600"
    backgroundColor="white"
    foregroundColor="#ecebeb"
    style={{ maxWidth: '100%', width: '100%' }}
  >
    <rect x="0" y="0" rx="40" ry="40" width="380" height="280" />
    <rect x="0" y="314" rx="10" ry="10" width="239" height="48" />
    <rect x="2" y="412" rx="10" ry="10" width="183" height="27" />
    <rect x="0" y="473" rx="25" ry="25" width="380" height="73" />
  </ContentLoader>
);

export default SkeletonCard;
