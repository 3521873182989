import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import ApartmentsSearch from '../components/ApartmentsSearch';
import ApartmentsList from '../components/ApartmentsList';
import ContactUs from '../components/ContactUs';

const Home = () => {
  const [availability, setAvailability] = useState<boolean[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      <Box
        id="home"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          mt: 11,
          pb: { lg: 15, md: 10, xs: 5 },
        }}
      >
        <Typography
          sx={(theme) => ({
            fontSize: { lg: 80, md: 36, xs: 24 },
            fontWeight: 800,
            textAlign: 'center',
            mb: { lg: 10.875, xs: 5.5 },
            mt: 3,
          })}
        >
          Find a perfect apartment <br /> to enjoy your vacation
        </Typography>
        <img
          src="./background.jpg"
          alt="background"
          style={{
            alignItems: 'center',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
          }}
        />
        <ApartmentsSearch
          availability={availability}
          setAvailability={setAvailability}
          loading={loading}
          setLoading={setLoading}
        />
      </Box>
      <ApartmentsList availability={availability} loading={loading} />
      <Box
        sx={{
          padding: '20px 0',
        }}
      >
        <ContactUs />
      </Box>
    </>
  );
};

export default Home;
