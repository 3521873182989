import React from 'react';

import { Box, Container, Icon, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isHomePage = !location.pathname.includes('/apartments/');
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 20,
          height: 90,
          backgroundColor: '#FBEDDA',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 100,
          borderBottom: '0.5px solid #cecece',
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: 1586,
            display: 'flex',
          }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography
              fontFamily={'SerpentineSansW01-Bold'}
              sx={{ fontSize: { lg: 36, md: 20 } }}
            >
              BENIDORM HOLIDAY APTS
            </Typography>
          </Link>
          <Box sx={{ display: 'flex', alignItems: 'end', ml: 'auto' }}>
            {isHomePage ? (
              <>
                <ScrollLink className="link" to="home" offset={-90}>
                  Home
                </ScrollLink>
                <ScrollLink className="link" to="contact-us">
                  Contact Us
                </ScrollLink>
              </>
            ) : (
              <div></div>
            )}
            <Box>
              <a
                href="https://www.facebook.com/profile.php?id=100070487254920"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon sx={{ fontSize: 30, color: '#000', mr: 1 }}>
                  <WhatsAppIcon />
                </Icon>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100070487254920"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon sx={{ fontSize: 30, color: '#000' }}>
                  <FacebookIcon />
                </Icon>
              </a>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Header;
