import { Box } from '@mui/material';
import React, { FC, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

interface MapLocationProps {
  latitude: number;
  longitude: number;
}

const MapLocation: FC<MapLocationProps> = ({ longitude, latitude }) => {
  const [viewport, setViewport] = useState({
    latitude,
    longitude,
    zoom: 15,
  });

  const openGoogleMaps = () => {
    const isConfirmed = window.confirm('Open in Google Maps?');
    if (isConfirmed) {
      window.open(`https://www.google.com/maps/place/${latitude},${longitude}`);
    }
  };
  return (
    <>
      <Box sx={{ height: { md: 600, xs: 200 } }}>
        <ReactMapGL
          style={{
            height: '100%',
            width: '100%',
            borderRadius: 25,
          }}
          mapboxAccessToken="pk.eyJ1IjoiYm9nZHVuMTciLCJhIjoiY2w4NG5sdHUzMDFvZDQwbzdvZm1nMnRiYyJ9.07cbj43Gix79lTCsYyao5Q"
          mapStyle="mapbox://styles/bogdun17/cl84oavkn006v15lkrarksij4"
          {...viewport}
          onMove={(evt) => setViewport(evt.viewState)}
          onClick={openGoogleMaps}
        >
          <Marker latitude={latitude} longitude={longitude}></Marker>
        </ReactMapGL>
      </Box>
    </>
  );
};

export default MapLocation;
