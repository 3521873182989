import { Box, Typography, Snackbar } from '@mui/material';
import React, { useState } from 'react';

const ContactUs = () => {
  const [isCopied, setIsCopied] = useState(false);
  const phoneNumber = '+34 658 84 05 51';

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(phoneNumber)
        .then(() => {
          setIsCopied(true);
        })
        .catch((err) => {
          console.error('Ошибка при копировании в буфер обмена:', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = phoneNumber;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setIsCopied(true);
    }
  };

  return (
    <>
      <Typography sx={{ fontSize: { sm: 58, xs: 46 }, fontWeight: 700, mb: 4 }}>
        Contact Us
      </Typography>
      <Box
        id="contact-us"
        sx={{
          display: { sm: 'flex', xs: 'block' },
          justifyContent: 'space-around',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: 1.5,
            cursor: 'pointer',
            justifyContent: 'center',
          }}
          onClick={copyToClipboard}
        >
          <Typography sx={{ mr: 3 }}>Tel. </Typography>
          <Typography
            sx={{
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {phoneNumber}
          </Typography>
          <Snackbar
            open={isCopied}
            autoHideDuration={1500}
            onClose={() => setIsCopied(false)}
            message="Copied"
          />
        </Box>

        <a
          style={{ textDecoration: 'none', color: 'black' }}
          href="https://www.facebook.com/profile.php?id=100070487254920"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            sx={{
              mb: 1.5,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Facebook
          </Typography>
        </a>
        <a
          style={{ textDecoration: 'none', color: 'black' }}
          href="https://wa.me/+34658840551"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            sx={{
              mb: 1.5,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            WhatsApp
          </Typography>
        </a>
      </Box>
    </>
  );
};

export default ContactUs;
